require('../../../booking_app/services/api-data.service')
require('../../../booking_app/services/landing-page.service')
require('../../../booking_app/services/result_modifiers/prioritise-translation-modifier')
require('../../../booking_app/services/cookie.service')
require('../../../booking_app/values/kaligo-search')
require('../../../booking_app/services/utils')
angular.module('BookingApp')
.config [

  "$routeProvider", "KaligoConfig"
  ($routeProvider, KaligoConfig) ->

    return if KaligoConfig.ignoreAngularRoute

    $routeProvider
    .when("/terms/chinaeastern-privacy-policy"
      controller: "PageCtrl",
      templateUrl: "/html/terms/privacy_policy2"
    ).when("/terms/privacy"
      redirectTo: "/terms/chinaeastern-privacy-policy"
    ).when("/terms/chinaeastern-terms-conditions"
      controller: "PageCtrl"
      templateUrl: "/html/terms/terms_condition2"
    ).when("/terms/terms_condition"
      redirectTo: "/terms/chinaeastern-terms-conditions"
    )
]

.controller 'ChinaEasternCtrl', [

  '$scope', '$rootScope', '$modal', '$timeout', "$location"
  'KaligoConfig', 'ApiDataService', "PrioritiseTranslationModifier",
  "LandingPageService", "CookieService", 'KaligoSearch', "UtilsService",
  "AppSettings", "HotelsSearchService"

  ($scope, $rootScope, $modal, $timeout, $location
  KaligoConfig, ApiDataService, PrioritiseTranslationModifier,
  LandingPageService, CookieService, KaligoSearch, UtilsService,
  AppSettings, HotelsSearchService) ->

    AppSettings.enableChildrenSearch = true
    AppSettings.checkoutRedirectToBookingsPath = true
    AppSettings.displayPurchaseAccessAndCancelText = true
    AppSettings.contactUsConfig.contactUsParagraphText = "wl.ce.contact_us_message"
    AppSettings.reloadOnSearch = true
    AppSettings.hotelDetailsTemplateConfig.showBgPageColor = true
    AppSettings.newLandingPageTiles = false

    $rootScope.globalState.useMembershipNoAsEmail = true
    $rootScope.globalState.hideUseAddressFromProfile = false
    $rootScope.globalState.hideFooter = false
    $rootScope.globalState.requireLoginOnCheckout = false
    $rootScope.globalState.useSimpleLoader = true
    $rootScope.globalState.checkoutHideUserDetailName = false
    $rootScope.globalState.serviceEmail = "service@kaligo.com"
    $rootScope.globalState.productType = KaligoConfig['PRODUCT_TYPE']
    $rootScope.globalState.search_results.modifiers = [PrioritiseTranslationModifier]
    $rootScope.globalState.alwaysAskForEmail = true
    $rootScope.globalState.displayMembershipAtCheckout = true
    $rootScope.globalState.loginEndpoint = '/api/chinaeastern/sign_in'
    $rootScope.globalState.signupEndpoint = '/api/chinaeastern/register'
    $rootScope.globalState.forgetPassEndpoint = '/api/chinaeastern/password'
    $rootScope.globalState.pageTitle = 'China Eastern'


    $rootScope.chinaeastern = {
      isVoucherProduct: $rootScope.globalState.productType == 'voucher'
    }

    if $rootScope.landingPage.hasProductType("voucher")
      $rootScope.globalState.requestVoucher = {
        url: "http://shopping.ceair.com/search?key_word=kaligo&category3_id=",
        linkText: "wl.ce.request_voucher"
      }

    $rootScope.chinaeastern.switchProduct = (dlpUrl, productType) ->
      ApiDataService.get("landing_pages/#{dlpUrl}")
        .then((landingPage) ->
          $rootScope.globalState.productType = productType
          $rootScope.landingPage = LandingPageService.init(landingPage)
          $rootScope.chinaeastern.isVoucherProduct = productType == 'voucher'
      )

    # Chinaeastern does not have 'earn' contract with TE so we redirect any
    # attempts to access views relating to earn to the homepage and change
    # the productType to 'voucher'. Temporary until all earn views are deleted.
    if $location.search()['landingPage'] == 'wl-chinaeastern-earn'
      $rootScope.chinaeastern.switchProduct('wl-chinaeastern-voucher', 'voucher')
      $location.url('/')

    allowedCurrency = ['CNY', 'HKD', 'TWD', 'USD']
    $rootScope.allCurrencies = UtilsService.filterArrayAttr(
      $rootScope.allCurrencies,
      allowedCurrency,
      'code'
    )
    $rootScope.enabledLocales = UtilsService.filterArrayAttr(
      $rootScope.enabledLocales,
      ['zh', 'hk', 'en'],
      'code'
    )

    defaultCurrency = $rootScope.currenciesService.findByCode('CNY')
    if $rootScope.selectedCurrency.code not in allowedCurrency
      # Need to use angular.copy here!
      $rootScope.selectedCurrency = angular.copy(defaultCurrency)
      CookieService.setCurrency($rootScope.selectedCurrency.code)

    $rootScope.convert_rate = $rootScope.selectedCurrency.rate

    updateCEUrl = () ->
      if $rootScope.selectedLocale && $rootScope.selectedLocale.lang_code == "en"
        $rootScope.chinaeastern.officialSite = "http://en.ceair.com"
        $rootScope.chinaeastern.aboutPage = "http://en.ceair.com/about/zjdh/index.html"
      else if $rootScope.traditionalChineseUser()
        $rootScope.chinaeastern.officialSite = "http://hk.ceair.com"
        $rootScope.chinaeastern.aboutPage = "http://ck.ceair.com/muovc/newsite/main/zh_HK/Static_pages/AboutChinaeastern.html"
      else
        $rootScope.chinaeastern.officialSite = "http://www.ceair.com"
        $rootScope.chinaeastern.aboutPage = "http://www.ceair.com/about/zjdh/index.html"
      return

    $rootScope.chinaeastern.quickSearch = (id, label) ->
      KaligoSearch.form.destination =
        label: label
        value: {
          destination_id: id
          type: 'city'
        }
      $timeout(
        () ->
          HotelsSearchService.perform()
        , 100
      )

    $rootScope.$on "$routeChangeSuccess", (e, current, previous) ->
      $rootScope.globalState.alertMsg = "" if previous

      # catch 404 angular route path errors
      return if !current || !current.$$route

      currentController = current.$$route.controller
      $rootScope.globalState.hideFooter = (currentController == "ResultsCtrl")

    triggerCloseDropdown = ->
      $(document).trigger($.Event('click.bs.dropdown.data-api'))

    $rootScope.$watch 'selectedLocale', (newvalue, oldvalue) ->
      return if !$rootScope.selectedLocale
      $rootScope.globalState.pageTitle = 'wl.ce.page_title'
      updateCEUrl()
      triggerCloseDropdown()

    $rootScope.$watch 'selectedCurrency', (newvalue, oldvalue) ->
      triggerCloseDropdown()

    updateCEUrl()

    return

]
